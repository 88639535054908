const selfOrClosest = (element, selector) => {
    return element.matches(selector) && element || element.closest(selector)
}

class Accordion {

    constructor(element) {
        this.element = element

        this.handleButtonClick = this.handleButtonClick.bind(this)
    }

    handleButtonClick(event) {
        const button = selfOrClosest(event.target, '[aria-controls]')
        const activeButton = this.element.querySelector('.active [aria-controls]')
    
        event.preventDefault()
    
        if (button == activeButton) {
          this.toggleItemState(activeButton)
          return;
        }
    
        if (activeButton) {
          this.toggleItemState(activeButton)
        }
    
        this.toggleItemState(button)
    }

    toggleItemState(button) {

        const contentId = button.getAttribute('aria-controls')
        const itemWrapper = button.closest('[data-accordion-item]')
        const contentElement = itemWrapper.querySelector(`#${contentId}`)
    
        if (itemWrapper.classList.contains('active')) {
          itemWrapper.classList.remove('active')
          contentElement.style.maxHeight = '0px'
          return;
        }
    
        itemWrapper.classList.add('active')
        const itemContent = contentElement.querySelector('[data-accordion-item-content]')
        const itemContentRect = itemContent.getBoundingClientRect()
    
        contentElement.style.maxHeight = `${itemContentRect.height}px`
      }

    init() {
        this.buttons = this.element.querySelectorAll('[aria-controls]')        

        this.buttons.forEach((button) => {
            button.removeEventListener('click', this.handleButtonClick)
            button.addEventListener('click', this.handleButtonClick)
        })
    }

}



export default class {
    constructor({
        
    }) {
        const accordionElems = Array.from(document.querySelectorAll('[data-accordion]'))
        const accordions = accordionElems.map((accordionElem) => {
            const accordion = new Accordion(accordionElem)
            accordion.init()
            return accordion
        })

        console.log(accordions)
    }
}