import { events, emitEvent } from '../utilities/custom-events';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        hotspotHandle,
        buttonHandle,
        featureHandle,
        prevHandle,
        nextHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const hotspots = Array.from(el.querySelectorAll(hotspotHandle));
        const prevButtons = Array.from(el.querySelectorAll(prevHandle));
        const nextButtons = Array.from(el.querySelectorAll(nextHandle));
        const features = Array.from(el.querySelectorAll(featureHandle));
        const listSlider = document.querySelector('.list-slider')
        const totalFeatures = features.length

        // State variables
        let activeIndex = 0;

        // Helper functions
        function setActiveIndex(i) {

            features.forEach((feature, index) => {
                if (i === index) {
                    feature.classList.add(activeClass)
                    return;
                }

                feature.classList.remove(activeClass)
            })

            hotspots.forEach((feature, index) => {
                if (i === index) {
                    feature.classList.add(activeClass)
                    return;
                }

                feature.classList.remove(activeClass)
            })

            if (window.innerWidth < 992) {
                scrollTop(listSlider, 100, () => {})
            }

            activeIndex = i;
        }

        function setActiveIndexFromElement(target) {
            const targetFeature = target.matches('[data-index]') && target || target.closest('[data-index]')
            setActiveIndex(parseInt(targetFeature.dataset.index))
        }

        function handleIndexClick(event) {
            setActiveIndexFromElement(event.target)
        }

        function handleNextClick() {

            let nextIndex = activeIndex + 1;
            
            if (nextIndex > (totalFeatures - 1)) {
                nextIndex = 0
            }

            console.log('next index', nextIndex)

            setActiveIndex(nextIndex)
        }

        function handlePreviousClick() {

            let prevIndex = activeIndex - 1;
            
            if (prevIndex < 0) {
                prevIndex = totalFeatures - 1
            }

            setActiveIndex(prevIndex)
        }

        // Add event listeners
        hotspots.forEach((hotspot, i) => {
            hotspot.addEventListener('click', handleIndexClick);
        });

        nextButtons.forEach((button) => {
            button.addEventListener('click', handleNextClick);
        })

        prevButtons.forEach((button) => {
            button.addEventListener('click', handlePreviousClick);
        })
    }
}
