import debounce from '../utilities/debouncer';

const TIMER_DURATION = 5000;
const ANIMATION_DURATION = 300;
const IS_TOUCH = 'ontouchstart' in window;
const EVENTS = {
    POINTER_DOWN: IS_TOUCH ? 'touchstart' : 'mousedown',
    POINTER_MOVE: IS_TOUCH ? 'touchmove' : 'mousemove',
    POINTER_UP: IS_TOUCH ? 'touchend' : 'mouseup',
    POINTER_OUT: IS_TOUCH ? 'touchleave' : 'mouseout',
};

export default class {
    constructor({
        id,
        sliderHandle,
        prevHandle,
        nextHandle,
        counterHandle,
        activeClass,
        animateClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const slider = el.querySelector(sliderHandle);
        const slides = slider.children;
        const prev = document.querySelector(prevHandle);
        const next = document.querySelector(nextHandle);
        const counters = document.querySelectorAll(counterHandle);
        const slidesLength = slides.length;
        const dragThreshold = 100;

        // State variables
        let activeIndex = 0; // The active index of the slide items
        let activeSlide = 0; // The active slide in view
        let timer = null;
        let dragging = false;
        let dragDist = 0;
        let lastX = null;
        let offset = 0;
        let forward = true;

        // Helper functions
        function resetTimer() {
            Array.from(counters).forEach((counter, i) => {
                if (activeSlide === i) {
                    counter.classList.add(activeClass);
                } else {
                    counter.classList.remove(activeClass);
                }
            });

            clearTimeout(timer);
            timer = setTimeout(() => {
                handleSlide(activeIndex + 1); // eslint-disable-line no-use-before-define
            }, TIMER_DURATION);
        }

        // Event handler functions
        function moveSlides(dist = 0, cb = null) {
            Array.from(slides).forEach(slide => {
                slide.style.transform = `translateX(${dist}px)`;
            });

            resetTimer();

            if (cb) cb();
        }
        function handleSlide(i, animate = true) {
            const currentIndex = activeIndex;
            const nextIndex = i;

            let jump = Math.abs(currentIndex - nextIndex);

            function updateSlides() {
                // Wait for slider animation to finish before updating slide order
                setTimeout(() => {
                    el.classList.remove(animateClass);

                    if (nextIndex > currentIndex) {
                        // Forward
                        activeSlide = activeSlide + jump < slidesLength ? activeSlide + jump : 0;

                        while (jump > 0) {
                            slider.appendChild(slides[0]);
                            jump--;
                        }
                    } else {
                        // Backward
                        activeSlide = activeSlide - jump < 0
                            ? slidesLength - jump
                            : activeSlide - jump;

                        while (jump > 0) {
                            slider.insertBefore(slides[slides.length - 1], slides[0]);
                            jump--;
                        }
                    }

                    // Update state variables
                    activeIndex = slidesLength;
                    offset = -1 * slides[activeIndex].offsetLeft;

                    // Remove 'active-slide' class from all slides
                    Array.from(slides).forEach(slide => {
                        slide.classList.remove('active-slide');
                    });

                    // Add 'active-slide' class to the current active slide
                    slides[activeIndex].classList.add('active-slide');

                    moveSlides(offset);
                }, ANIMATION_DURATION);
            }

            const cb = currentIndex !== nextIndex ? updateSlides : null;

            // Update state variables
            activeIndex = i;
            offset = -1 * slides[activeIndex].offsetLeft;

            if (animate) el.classList.add(animateClass);
            moveSlides(offset, cb);
        }

        // Event handler functions
        const handleResize = debounce(() => {
            // Update state variables
            offset = -1 * slides[activeIndex].offsetLeft;

            moveSlides(offset);
        }, 500);
        function handlePointerMove(e) {
            e.stopPropagation();

            if (!dragging) return;

            const clientX = e.clientX || e.touches[0].clientX;
            const diff = clientX - lastX;

            // Update state variables
            lastX = clientX;
            dragDist += diff;
            offset += diff;
            forward = diff < 0;

            moveSlides(offset);
        }
        function handlePointerDown(e) {
            // Update state variables
            dragging = true;
            lastX = e.clientX || e.touches[0].clientX;

            el.addEventListener(EVENTS.POINTER_MOVE, handlePointerMove);
        }
        function handlePointerUp() {
            if (!dragging) return; // For some reason this get called a bunch of times

            const belowThreshold = Math.abs(dragDist) < dragThreshold;

            // Update state variables
            dragging = false;
            dragDist = 0;

            el.removeEventListener(EVENTS.POINTER_MOVE, handlePointerMove);

            if (belowThreshold) {
                handleSlide(activeIndex);
            } else if (forward) {
                handleSlide(activeIndex + 1);
            } else {
                handleSlide(activeIndex - 1);
            }
        }
        function handlePrev() {
            handleSlide(activeIndex - 1);
        }
        function handleNext() {
            handleSlide(activeIndex + 1);
        }
        const handleClicks = Array.from(counters).map((counter, i) => () => {
            handleSlide(activeIndex + (i - activeSlide));
        });

        // Add event listeners
        window.addEventListener('resize', handleResize);
        el.addEventListener(EVENTS.POINTER_DOWN, handlePointerDown);
        el.addEventListener(EVENTS.POINTER_UP, handlePointerUp);
        el.addEventListener(EVENTS.POINTER_OUT, handlePointerUp);
        if (prev) prev.addEventListener('click', handlePrev);
        if (next) next.addEventListener('click', handleNext);
        Array.from(counters).forEach((counter, i) => {
            counter.addEventListener('click', handleClicks[i]);
        });

        // Initialize
        Array.from(slides).reverse().forEach(slide => {
            const clone = slide.cloneNode(true);
            const reference = slides[0];

            slider.insertBefore(clone, reference);
        });
        activeIndex = slidesLength;
        handleSlide(activeIndex, false);
    }
}
